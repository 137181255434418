.sentimentModelShadow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .sentimentModelContainer {
    width: 800px;
    height: 80%;
    background: #fff;
    border-radius: 8px;
    position: relative;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 16px;
      border-bottom: 1px solid #dee2e6;

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .AI {
          color: #006f7e;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

          border-radius: 4px;
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.8) 0%,
              rgba(255, 255, 255, 0.8) 100%
            ),
            #006f7e;
          padding: 6px;
        }
      }

      .crosslogo {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    }

    .middle {
      overflow-y: scroll;
      height: 90%;

      padding: 16px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c5c5c5;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .summaryContainer {
        padding: 16px;
        border-radius: 8px;
        background: rgba(0, 111, 126, 0.08);
        .content {
          color: #212a39;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }

        margin-bottom: 16px;
      }

      .actionItemContainer {
        padding: 16px;
        border-radius: 8px;
        background: rgba(0, 111, 126, 0.08);

        .head {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;

          .icon {
            width: 24px;
            height: 24px;
          }

          .title {
            color: #212a39;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 25.2px */
          }
        }

        .content {
          p {
            color: #212a39;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
}

.managePOCModelShadow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .managePOCModelContainer {
    width: 800px;
    height: 90%;
    background: #fff;
    border-radius: 8px;
    position: relative;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 16px;
      border-bottom: 1px solid #dee2e6;

      p {
        color: #3c4852;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        span {
          color: rgba(60, 72, 82, 0.8);
          font-weight: 400;
        }
      }

      .crosslogo {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    }

    .middle {
      overflow-y: scroll;
      height: 82%;

      padding: 16px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c5c5c5;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .Disclamer {
        height: 40px;
        padding: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        border-radius: 4px;
        background: rgba(233, 148, 13, 0.08);
        margin-bottom: 16px;

        .dis_image {
          height: 24px;
          width: 24px;
        }

        p {
          color: #a36809;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .POC {
        .heading {
          padding-bottom: 16px;
          color: #3c4852;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 133.333% */
        }

        .pocsSet {
          padding: 8px;
          border-radius: 16px;
          background: rgba(5, 86, 70, 0.04);
          margin-bottom: 8px;

          .head {
            padding-bottom: 8px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              color: #055646;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px; /* 160% */
            }

            .deleteBtn {
              cursor: pointer;
            }
          }

          .inputSet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;

            .selectInput {
              background: none;
              height: 56px;
              padding: 16px;
              border-radius: 8px;
              border: 1.5px solid rgba(60, 72, 82, 0.4);

              select {
                width: 100%;
                background: none;
                border: none;
                outline: none;
              }
            }

            .numberInput {
              height: 56px;
              padding: 16px;
              border-radius: 8px;
              border: 1.5px solid rgba(60, 72, 82, 0.4);

              p {
                color: #232323;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 27px */
              }
            }
          }
        }

        .addPoc {
          cursor: pointer;
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: center;
          padding: 10px;
          width: 120px;
          border-radius: 8px;
          border: 1.5px solid #035642;
          background: #fff;

          .logo {
            width: 16px;
            height: 16px;
          }

          .title {
            color: #035642;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.07px;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      padding: 16px;
      border-top: 1px solid #dee2e6;

      display: flex;
      justify-content: end;
      gap: 8px;

      position: absolute;
      bottom: 0;

      .saveBtn {
        width: 166px;
        height: 40px;
        background: #035642;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        p {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .cancelBtn {
        cursor: pointer;
        width: 166px;
        height: 40px;
        background: #6c757d;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        p {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

.successShadow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  .successContainer {
    width: 480px;
    min-height: 300px;
    background: #fff;
    border-radius: 8px;
    position: relative;

    .top {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      padding: 16px;
      // border-bottom: 1px solid #dee2e6;

      p {
        color: #3c4852;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        span {
          color: rgba(60, 72, 82, 0.8);
          font-weight: 400;
        }
      }

      .crosslogo {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    }

    .body {
      padding: 0px 40px 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-bottom: 32px;

        color: #a09fa0;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 32px;
        color: #3c4852;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .tickImage {
        margin-bottom: 32px;
        height: 160px;
        width: 160px;
      }

      .okayBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #035642;
        width: 166px;
        height: 40px;
        p {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

.inboundParent {
  padding: 24px;
  height: 100vh;

  overflow-y: hidden;

  .inboundHeader {
    .inboundHeading {
      padding-bottom: 24px;
      p {
        color: #575757;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .inboundTabBody {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background: linear-gradient(
        0deg,
        rgba(33, 42, 57, 0.15) 0%,
        rgba(33, 42, 57, 0.15) 100%
      );
      border-radius: 8px;

      margin-bottom: 24px;

      .inboundTab {
        cursor: pointer;
        height: 48px;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #3c4852;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        letter-spacing: -0.304px;
      }
    }
  }

  .inboundScroll {
    height: calc(100vh - 156px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .inboundBody {
      padding-right: 16px;

      .defaultPoc {
        margin-bottom: 24px;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid #097252;
        background: linear-gradient(
          0deg,
          rgba(9, 114, 82, 0.04) 0%,
          rgba(9, 114, 82, 0.04) 100%
        );

        .top {
          padding-bottom: 16px;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .heading {
            color: #3c4852;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 177.778% */
            span {
              font-weight: 400;
            }

            .infologo {
              padding-left: 4px;
              cursor: pointer;
            }
          }

          .editdefault {
            cursor: pointer;
            display: flex;
            gap: 8px;
            align-items: center;

            border-radius: 4px;
            border: 1px solid #3c4852;
            padding: 6px 8px;

            .logo {
              width: 16px;
              height: 16px;
            }

            .title {
              color: #3c4852;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
            }
          }
        }

        .middle {
          padding-bottom: 16px;
          color: #388d74;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .bottom {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          .poc {
            .title {
              color: rgba(9, 114, 82, 0.8);
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }

            .names {
              span {
                display: block;
                color: #3c4852;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 171.429% */
              }
            }
          }
        }
      }

      .customPoc {
        margin-bottom: 24px;
        .middle {
          padding-bottom: 16px;
          color: #388d74;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .heading {
          margin-bottom: 8px;
          p {
            color: #3c4852;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 177.778% */
          }
        }

        .formSection {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .inputs {
            display: flex;
            gap: 8px;

            .inputLabel {
              .label {
                color: #3c4852;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;

                padding-bottom: 4px;
              }

              .selectInput {
                border-radius: 4px;
                border: 1px solid rgba(60, 72, 82, 0.48);
                background: #fff;
                padding: 12px;

                width: 200px;

                select {
                  width: 100%;
                  background: none;
                  outline: none;
                  border: none;
                }
              }
            }
          }

          .button {
            height: 48px;
            border-radius: 8px;
            background: #055646;
            padding: 16px;
            p {
              color: #fff;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }

        .emptyScreen {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .emptyImage {
            margin-bottom: 24px;
            width: 357px;
            height: 242px;
          }

          p {
            color: #3c4852;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
        }
      }
    }

    .learnerBody {
      padding-right: 16px;

      .formSection {
        display: flex;
        // justify-content: space-between;
        align-items: end;

        gap: 16px;

        .inputs {
          display: flex;
          align-items: end;
          gap: 8px;

          .inputLabel {
            .label {
              color: #3c4852;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;

              padding-bottom: 4px;
            }

            .selectInput {
              border-radius: 4px;
              border: 1px solid rgba(60, 72, 82, 0.48);
              background: #fff;
              padding: 12px;

              width: 300px;

              select,
              input {
                width: 100%;
                background: none;
                outline: none;
                border: none;
              }
            }
          }
        }

        .button {
          height: 48px;
          border-radius: 8px;
          background: #055646;
          padding: 12px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }

      .learnerCard {
        margin-top: 24px;
        border-radius: 4px;
        background: rgba(9, 114, 82, 0.04);
        padding: 16px;

        .title {
          color: #3c4852;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */

          padding-bottom: 8px;
        }

        .details {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;
          .detailSet {
            .label {
              color: rgba(9, 114, 82, 0.8);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }

            .value {
              margin-top: 0px;
              color: #097252;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 171.429% */
            }
          }
        }
      }

      .tableSection {
        margin-top: 24px;
        overflow-x: auto;

        .middle {
          // padding-bottom: 16px;
          color: #388d74;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .title {
          color: #3c4852;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .emptyScreen {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .emptyImage {
          margin-bottom: 24px;
          width: 357px;
          height: 242px;
        }

        p {
          color: #3c4852;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }

      .emptyScreen2 {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .emptyImage {
          margin-bottom: 24px;
          width: 357px;
          height: 242px;
        }

        p {
          color: #3c4852;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
    }
  }
}

// ManagePOC model
// width : 900
// height : 637
