.kachingViewer {
  .mainContainer {
    align-items: flex-start;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: 2rem;
    width: 100%;
  }
  .kcv_dateInput {
    width: 180px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(60, 72, 82, 0.35);
    height: 56px !important;
  }

  .filters {
    display: flex;
  }

  .notes-header {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
  }
}
