.table-wrapper {
  margin-top: 24px;
  display: flex;
  width: 100%;
}

.fixed-columns,
.scrollable-columns {
  table-layout: fixed;
}

.fixed-columns {
  flex: 0 0 auto;
  width: 500px;
  overflow: hidden;
  border-right: 1px solid #ddd;
}

.scrollable-columns {
  flex: 1;
  overflow-x: auto;
}

.fixed-column {
  background-color: #f4f4f4;
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 1px solid #ddd;
}

.fixed-column2 {
  background-color: #f4f4f4;
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 1px solid #ddd;
  width: 200px;
}

.fixed-column2 > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

thead {
  background-color: #f4f4f4;
}

th,
td {
  padding: 10px;
  text-align: left;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 65px;
}

th {
  font-weight: bold;
}

.scrollable-columns table {
  min-width: 1000px;
}

.sn-column {
  width: 80px;
  text-align: center;
}

.action-btn {
  border-radius: 4px;
  background: #055646;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  padding: 8px;
}

.record-column {
  width: 340px;
}

.summary-column {
  width: 120px;
}

.poc-column {
  width: 160px;
}

.response-column {
  width: 160px;
}

.genAI {
  width: 32px;
  height: 32px;

  cursor: pointer;
}

.agent-hover-class:hover {
  background: rgb(207, 207, 207);
}
