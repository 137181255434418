@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

.onboardmail {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  gap: 1rem;
  font-family: 'Source Sans Pro';

  .disabled {
    opacity: 0.5;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    width: 100%;
  }

  .warning {
    color: red;
    font-size: 10px;
  }

  .app-round-dsply {
    margin-top: 10px;
    border-radius: 8px;
    background: #f0f0f0;
    // max-height: 50px;
    width: 100%;
  }

  .job-screen-dt-tog-btn3 {
    border: none;
    display: flex;
    width: 50%;
    // max-height: 50px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #055646;
    // margin-top: 10px;
    justify-content: center;
  }

  .btch-toggle-unchecked-3 {
    color: #055646;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .btch-toggle-3 {
    color: #fff;
    background-color: #055646;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .labs-table-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .labs-table-header-2 {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    min-width: 150px;
    width: inherit;
    padding-left: 4px;
    padding-right: 4px;
    height: 48px;
    cursor: pointer !important;
  }

  .labs-table-search {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    min-width: 150px;
    // width: inherit;
    padding-left: 4px;
    padding-right: 4px;
  }

  .list-learner-heading {
    color: #035642;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 10px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
  }

  .search-student-btn {
    display: flex;
    width: 150px;
    height: 56px;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #055646;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
  }

  .labs-table-btn-3 {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #035642;
    background-color: #fff;
    padding: 11px 22px;
  }

  .labs-table-btn-4 {
    // width: 120px;
    height: 35px;
    border: 1px solid #dc3545;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #dc3545;
    background-color: #fff;
    padding: 11px 22px;
  }

  .labs-table-btn-download {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #035642;
    background-color: #fff;
    padding-left: 9px;
    padding-right: 9px;
  }

  .job-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    flex-direction: column;
    width: inherit;
    // padding-bottom: 200px;
  }

  .btch-search {
    display: flex !important;
    justify-content: flex-end;
    margin: unset;
    z-index: 999;
    border-radius: 4px;
    border: 1px solid rgba(60, 72, 82, 0.5);
    opacity: 0.75;
    // width: 209px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 8px;
    min-width: 180px;
  }

  .btch-search-label {
    margin-top: -8px;
  }

  .as-dl-search {
    height: 40px;
    width: 300px !important;
    display: inline-block !important;
  }

  .full-height {
    height: 100% !important;
  }

  .toggle-container2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    // padding-bottom: 24px;
  }

  .lectureTable {
    text-align: center;
    margin-top: 10px;
    border-collapse: collapse;
  }

  .lectureTable,
  .lectureTable > thead,
  .lectureTable > tbody,
  .lectureTable > thead > tr,
  .lectureTable > thead > tr > th,
  .lectureTable > tbody > tr,
  .lectureTable > tbody > tr > td {
    // border: 0.1px solid black;
    border-collapse: collapse;
  }

  .lectureTable > tbody > tr > td {
    font-weight: 500;
  }

  .lectureTable > thead > tr > th {
    font-weight: bold;
  }

  .action-icons {
    border: 0px !important;
  }

  .lectureTable > thead > tr > th,
  .lectureTable > tbody > tr > td {
    // white-space: nowrap;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .flex-100 {
    display: flex;
    width: 100%;
  }

  .width-60 {
    width: 70px;
  }

  .width-160 {
    width: 160px;
  }

  .width-260 {
    width: 260px;
  }

  .th-text {
    color: #035642;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 210%;
    background: rgba(5, 86, 70, 0.1);
    /* 22.4px */
  }

  .btn-success {
    padding: 6px 28px;
  }

  .th-cell {
    // color: #035642;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    background: #fff;
    text-wrap: wrap;
    /* 22.4px */
  }

  .btch-dt-foot-main {
    display: flex;
    margin-top: 20px;
    // margin-left: 250px;
    padding: 10px;
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    z-index: 300;
    background-color: #3c4852;
    position: fixed;
  }

  .btch-dt-foot-cnt {
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 16px;
    margin-left: 100px;
  }

  .btch-dt-foot-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 350px;
    height: 60px;
    /* flex-direction: column; */
    justify-content: center;
    flex-shrink: 0;
    // margin-right: 330px;
  }

  .btch-dt-foot-btn-3 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    // width: 350px;
    height: 60px;
    /* flex-direction: column; */
    justify-content: center;
    flex-shrink: 0;
    // margin-right: 330px;
  }

  .btch-dt-foot-btn1 {
    // width: 200px;
    border-radius: 10px;
    outline: none;
    font-weight: 600;
    color: #055646;
    margin-top: 10px;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 12px;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    visibility: hidden;
    background-color: #3c4852; /* Bootstrap danger red */
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    min-width: 150px;
    min-height: 40px;
    text-wrap: wrap !important;
    position: absolute;
    // bottom: 110%; /* Position above the button */
    line-height: 100%;
    left: 50%;
    transform: translateX(-50%);
    cursor: default;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 0.75;
  }
}

.MuiBox-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 3px 13px 0px rgb(0 0 0 / 17%);
}

.MuiTableRow-head {
  font-weight: 600;

  .MuiTableCell-root {
    font-weight: 600;
  }
}

.send-offer-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  font-family: 'Source Sans Pro' !important;
  // margin-top: 200px;
  margin-left: 35%;
  max-width: 640px;
  align-items: center;
  // left: 35%;

  .disabled {
    opacity: 0.5;
  }

  .offer-letter-heading {
    color: #3c4852;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .flex-100-heading {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 16px;
  }

  .error-heading {
    color: #dc3545;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
  }

  .success-heading {
    color: #a09fa0;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
  }

  .offer-success-text {
    color: #3c4852;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .flex-100-spbtwn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    // padding-bottom: 8px;
  }

  .flex-100-spbtwn-2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    padding-bottom: 8px;
  }

  .labs-table-header-2 {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    min-width: 150px;
    width: inherit;
    padding-left: 4px;
    padding-right: 4px;
    // height: 48px;
  }

  .labs-create-new-form-date_input {
    width: 80%;
    height: 52px;
    left: 406px;
    top: 216px;
    // margin-left: 10px;
    background: #ffffff;
    border: 1px solid rgba(60, 72, 82, 0.25);
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
  }

  .confirm-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    background-color: #035642;
    min-width: 150px;
  }

  .preview-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
    min-width: 150px;
  }

  .cancel-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    background-color: #6c757d;
    min-width: 150px;
  }

  .showJobPopupSkill {
    width: 260px;
    margin-top: 0px;
    border: 16px solid;
    border-color: transparent;
    margin-left: 0px;
    position: absolute;
    z-index: 100;
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    font-size: 12px;
  }

  .preview-footer-div {
    display: flex;
    justify-content: end;
  }
}

.preview-offer-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 2rem;
  gap: 1rem;
  font-family: 'Source Sans Pro' !important;
  margin-top: 3vh;
  margin-left: 25%;
  max-width: 900px;
  // left: 35%;

  .disabled {
    opacity: 0.5;
  }

  .preview-footer-div {
    display: flex;
    justify-content: space-between;
  }

  .confirm-footer-div {
    display: flex;
    justify-content: end;
  }

  .flex-100-spbtwn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    // padding-bottom: 8px;
  }

  .flex-100-center {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    // padding-bottom: 8px;
  }

  .offer-letter-heading {
    color: #3c4852;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .iframe-div {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    min-width: 150px;
    width: inherit;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 400px;
  }

  .labs-table-header-2 {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    min-width: 150px;
    width: inherit;
    padding-left: 4px;
    padding-right: 4px;
    height: 48px;
  }

  .confirm-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    background-color: #035642;
    min-width: 150px;
  }

  .preview-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
    min-width: 150px;
  }

  .cancel-btn {
    // width: 120px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    background-color: #6c757d;
    min-width: 150px;
  }

  .preview-footer-text {
    color: #24252e;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px;
    display: contents;
    /* 121.875% */
  }

  .preview-detail-heading {
    display: flex;
    gap: 15px;
    padding-top: 16px;
  }

  .preview-detail-detail-div {
    display: flex;
    width: 360px;
  }

  .student-detail-title-text {
    color: #212a39;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .student-detail-title-heading {
    color: #212a39;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .student-detail-detail-text {
    color: #035642;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 4px;
    padding-top: 1px;
  }

  .preview-first-div {
    width: 85%;
  }

  .preview-second-div {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }

  .img-icon {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #035642;
    background: #fff;
  }
}
